import React, {useState, useEffect, Dispatch, SetStateAction} from "react";

type IsActive = boolean;
type SetIsActive = Dispatch<SetStateAction<boolean>>;
/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.RefObject[]} els
 * @param {boolean} initialState
 * @param {number} duration
 * @param extraCallback
 */
const useDetectHover = (els: Array<React.RefObject<Element>>, initialState: boolean, duration: number = 200, extraCallback?: (x: IsActive) => void): [IsActive, SetIsActive] => {
    const [isActive, setIsActive] = useState(initialState);
    const [activeTimer, setActiveTimer] = useState(null);

    useEffect(() => {
        const onMouseOut = e => {
            let found = false;

            for (let el of els) {
                // If the active element exists and is hovered outside of
                if (el.current !== null && el.current.contains(e.target)) {
                    found = true;
                }
            }

            if (!found) {
                const timer = setTimeout(
                    () => {
                        console.log('Timeout fired');
                        setIsActive(false);
                        if (extraCallback) {
                            extraCallback(false);
                        }
                    }, duration
                );
                setActiveTimer(timer);
            }
        };

        const mouseIn = e => {
            let found = false;

            for (let el of els) {
                // If the active element exists and is hovered
                if (el.current !== null && el.current.contains(e.target)) {
                    found = true;
                }
            }

            // Mouse in happened!
            if (found) {
                console.log('hovered');
                // If there's currently a timer, clear it.
                if (activeTimer) {
                    console.log(', clearing timer')
                    clearTimeout(activeTimer);
                }
            }
        }

        // If the item is active (ie open) then listen for mouse exits
        if (isActive) {
            window.addEventListener("mouseover", mouseIn);
            window.addEventListener("mouseout", onMouseOut);
        }

        return () => {
            window.removeEventListener("mouseover", mouseIn);
            window.removeEventListener("mouseout", onMouseOut);
        };
    }, [isActive, els]);

    return [isActive, setIsActive];
};

export default useDetectHover;