import { graphql, StaticQuery } from "gatsby";
import "./footerv2.scss";
import "./newsletter-modal.scss";
import {
  getFooterV2,
  TypeFooterV2Data,
  TypeFooterV2LinkWithLogo,
} from "../../utils/queries/footerv2";
import * as React from "react";
import Linked from "../../utils/linkedItem";
import clsx from "classnames";
import HubspotForm from "../hubspot-form";

const FooterLogoLinkItem = ({ item }: { item: TypeFooterV2LinkWithLogo }) => {
  return (
    <div className="logo-link">
      <Linked className="flex" link_to={item.link} target="_blank">
        <img src={item.logo.url} alt={item.logo.alt} className="mr-1" />
        <span>{item.logo.alt}</span>
      </Linked>
    </div>
  );
};

export const FooterLinks: React.FC<{
  data: TypeFooterV2Data;
  location: Location;
}> = ({ data, location }) => {
  return (
    <>
      <div className="container flex flex-wrap">
        <div className="upper-footer flex flex-wrap w-full">
          <div className="link-column__logos w-full lg:w-auto flex flex-col items-center justify-between lg:mr-12">
            {data.g2_logo ? (
              <>
                <div className="grid small-logos items-center w-full justify-center">
                  <Linked
                    link_to={{
                      document: {
                        data: {
                          url: data.g2_logo_link2.url,
                          is_external: true,
                        },
                      },
                    }}
                    target="_blank"
                  >
                    <img
                      src={data.g2_logo2.url}
                      alt={data.g2_logo2.alt}
                      className="mr-0 g-img mx-auto"
                      loading="lazy"
                    />
                  </Linked>
                  <Linked
                    link_to={{
                      document: {
                        data: {
                          url: data.g2_logo_link.url,
                          is_external: true,
                        },
                      },
                    }}
                    target="_blank"
                  >
                    <img
                      src={data.g2_logo.url}
                      alt={data.g2_logo.alt}
                      className="mr-0 g-img mx-auto"
                    />
                  </Linked>
                  <Linked
                    link_to={{
                      document: {
                        data: {
                          url: data.g2_logo_link3.url,
                          is_external: true,
                        },
                      },
                    }}
                    target="_blank"
                  >
                    <img
                      src={data.g2_logo3.url}
                      alt={data.g2_logo3.alt}
                      className="mr-0 g-img mx-auto"
                    />
                  </Linked>
                  <Linked
                    link_to={{
                      document: {
                        data: {
                          url: data.g2_logo_link4.url,
                          is_external: true,
                        },
                      },
                    }}
                    target="_blank"
                  >
                    <img
                      src={data.g2_logo4.url}
                      alt={data.g2_logo4.alt}
                      className="mr-0 g-img mx-auto"
                    />
                  </Linked>
                </div>
              </>
            ) : (
              <>
                <Linked
                  link_to={{
                    document: { data: { url: "/", is_external: false } },
                  }}
                >
                  <img
                    src={data.desktop_logo.url}
                    className="lg:block hidden logo"
                    alt="amazee.io logo"
                  />
                </Linked>
                <Linked
                  link_to={{
                    document: { data: { url: "/", is_external: false } },
                  }}
                >
                  <img
                    src={data.desktop_logo.url}
                    className="block lg:hidden logo logo--small"
                    alt="amazee.io logo"
                  />
                </Linked>
              </>
            )}
          </div>
          <div className="menu-links links flex justify-between flex-wrap lg:flex-no-wrap flex-grow">
            {data.body.map((slice, index) => (
              <div
                key={index}
                className={clsx(
                  "link-column mt-7 lg:mt-0 pr-4 w-1/2 sm:w-auto",
                  slice?.items?.length > 0 ? "sub-links" : "no-sub-links"
                )}
              >
                <div className="title">
                  <Linked link_to={slice.primary.title_link}>
                    {slice.primary.title}
                  </Linked>
                </div>
                <div className="link-column__links flex flex-col lg:items-start">
                  {slice?.items?.map((item, index) => (
                    <Linked
                      key={index}
                      link_to={item.link}
                      target={
                        item.link.document.data.is_external === true
                          ? "_blank"
                          : "_self"
                      }
                    >
                      {item.text || item.link.document.data.friendly_name}
                    </Linked>
                  ))}
                </div>
              </div>
            ))}
            <div className="social-icons w-full border-t sm:border-t-0 sm:w-auto pt-3 sm:pt-0 sm:pl-3 sm:border-l link-column mt-7 lg:mt-0">
              <div className="title uppercase">Connect</div>
              <div className="link-column__links">
                {data.social_links.map((socialLink, index) => (
                  <FooterLogoLinkItem item={socialLink} key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row w-full lower-footer-wrap">
          <div className="legal-links flex flex-row flex-wrap sm:justify-center w-full my-3">
            {data.legal_links.map((legalLink, index) => (
              <div className="legal-link mr-3" key={index}>
                {index > 0 && (
                  <span className="pr-3 spacer inline-block">|</span>
                )}
                {legalLink.link.document.data.url == "/cookie-notice" ? (
                  <a
                    href={legalLink.link.document.data.url}
                    rel={legalLink.link_rel}
                  >
                    {legalLink.text ||
                      legalLink.link.document.data.friendly_name}
                  </a>
                ) : (
                  <Linked link_to={legalLink.link}>
                    {legalLink.text ||
                      legalLink.link.document.data.friendly_name}
                  </Linked>
                )}
              </div>
            ))}
          </div>
          <div className="copyright w-full flex justify-between flex-col lg:flex-row mb-2 lg:mb-0">
            <span>
              © 2024 amazee.io, part of{" "}
              <a href="https://www.mirantis.com/" target="_blank">
                Mirantis
              </a>
              . All rights reserved.
            </span>
            <span className="flex items-center copyright-one">
              Made with{" "}
              <svg
                className="mx-1"
                id="Group_15853"
                data-name="Group 15853"
                xmlns="http://www.w3.org/2000/svg"
                width="14.95"
                height="14"
                viewBox="0 0 14.95 14"
              >
                <defs>
                  <clipPath id="clip-path">
                    <rect
                      id="Rectangle_3673"
                      data-name="Rectangle 3673"
                      width="14.95"
                      height="14"
                      fill="none"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Group_15852"
                  data-name="Group 15852"
                  clip-path="url(#clip-path)"
                >
                  <path
                    id="Path_31397"
                    data-name="Path 31397"
                    d="M8.856,13.268c-.165.1-.4.225-.573.318-.279.149-.579.265-.859.414a.589.589,0,0,0-.159-.064c-.03-.011-.471-.235-.572-.286a.9.9,0,0,0-.128-.1c-.113-.06-.307-.147-.381-.191C3.281,11.64-.225,8.025.012,4.408.253.714,4.625-1.468,7.472,1.147c3.626-3.077,8.787.5,7.174,5.551-.788,2.472-3.606,5.244-5.79,6.57"
                    fill="#00aeef"
                  />
                </g>
              </svg>
              accross the globe
            </span>
            <span>
              All trademarks are the property of their respective owners.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export class Footer extends React.Component<{
  data: TypeFooterV2Data;
  location: Location;
}> {
  render() {
    const { data } = this.props;
    let loc = null;

    try {
      loc = location;
    } catch { }

    return (
      <footer className="footer">
        <FooterLinks data={data} location={loc} />
      </footer>
    );
  }
}

export const StaticFooter: React.FC = ({ location }) => {
  const query = graphql`
    {
      allPrismicFooterV2 {
        nodes {
          data {
            body {
              ... on PrismicFooterV2BodyLinkColumn {
                id
                items {
                  text
                  link_rel
                  link {
                    document {
                      ... on PrismicPageDefinitions {
                        id
                        data {
                          url
                          is_external
                          friendly_name
                        }
                      }
                    }
                  }
                }
                primary {
                  title
                  title_link {
                    document {
                      ... on PrismicPageDefinitions {
                        id
                        data {
                          url
                          is_external
                          friendly_name
                        }
                      }
                    }
                  }
                }
                slice_type
              }
            }
            copyright
            desktop_logo {
              url
              alt
            }
            small_logo {
              url
              alt
            }
            g2_logo {
              url
              alt
            }
            g2_logo_link {
              url
            }
            g2_logo2 {
              url
              alt
            }
            g2_logo_link2 {
              url
            }
            g2_logo3 {
              url
              alt
            }
            g2_logo_link3 {
              url
            }
            g2_logo4 {
              url
              alt
            }
            g2_logo_link4 {
              url
            }
            legal_links {
              text
              link_rel
              link {
                document {
                  ... on PrismicPageDefinitions {
                    id
                    data {
                      url
                      is_external
                      friendly_name
                    }
                  }
                }
              }
            }
            newsletter_cta_text
            social_links {
              link {
                document {
                  ... on PrismicPageDefinitions {
                    id
                    data {
                      url
                      is_external
                    }
                  }
                }
              }
              logo {
                url
                alt
              }
            }
            mobile_logo {
              url
            }
          }
        }
      }
    }
  `;

  return (
    <StaticQuery
      render={(result) => (
        <Footer location={location} data={getFooterV2(result)} />
      )}
      query={`${query}`}
    />
  );
};

export default StaticFooter;
