import {
  PrismicImage,
  PrismicRichText,
  PrismicPageDefinition,
  PrismicExternalLink,
} from "./types";
import { graphql } from "gatsby";

export type bannerStyle = "black-white" | "blue-white";

export type TypeSecondLevelNavigationItem = {
  sub_nav_link_label: PrismicRichText;
  link_to: PrismicPageDefinition;
  with_indent: boolean;
  is_divider: boolean;
};

export type TypeTopLevelNavigationItem = {
  slice_type: string;
  primary: {
    label: PrismicRichText;
    link_to: PrismicPageDefinition;
  };
  items?: Array<TypeSecondLevelNavigationItem>; // Aka "subnav"
};

export type TypeMetaLevelNavigationItem = {
  banner_link_group: PrismicRichText;
  banner_link_group_to: PrismicExternalLink;
};

export type TypeNavigationData = {
  desktop_logo: PrismicImage;
  mobile_logo: PrismicImage;
  nav: Array<TypeTopLevelNavigationItem>;
  banner_links: Array<TypeMetaLevelNavigationItem>;
  banner_enabled: boolean;
  banner_image: PrismicImage;
  banner_content: PrismicRichText;
  banner_link: string;
  banner_link_to: PrismicExternalLink;
  banner_style: bannerStyle;
};

export type TypeNavigationQueryResult = {
  allPrismicNavigation: {
    edges: Array<{
      node: { data: TypeNavigationData };
    }>;
  };
};

export const getNavigation = (
  data: TypeNavigationQueryResult
): TypeNavigationData => {
  return data.allPrismicNavigation.edges[0].node.data;
};
