import * as React from "react";
import { StaticFooter } from "./footerv2/footerv2";
import { PageTitle } from "../components/title/title";
import StaticChildrenWrapper from "./child-wrapper";
import { StaticNavigationV2 } from "./navigation-v2/navigation";
import "../styles/bootstrap.css";
import "../styles/global.css";
import "../styles/tailwind.css";

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const AmazeePage = ({ children, location, id, classSet }) => {
  return (
    <ConditionalWrapper
      condition={id}
      wrapper={(children) => (
        <div id={id} className={classSet}>
          {children}
        </div>
      )}
    >
      <>
        <PageTitle />
        <StaticNavigationV2 location={location} />
        <StaticChildrenWrapper location={location}>
          <div id="navigation-dropdown-portal" />
          {children}
        </StaticChildrenWrapper>
        <StaticFooter />
      </>
    </ConditionalWrapper>
  );
};

export default AmazeePage;
