import React from 'react';
import { PrismicRichText, PrismicRichTextRaw, PrismicV2RichText } from "./queries/types";
import linkResolver from './linkResolver';
import * as _ from 'lodash';

const { RichText } = require('prismic-reactjs');

const RichTextV2Render = (something: PrismicV2RichText) => {
    if (something.type === 'heading1') {
        return <h1>{something.text}</h1>
    }
    if (something.type === 'heading2') {
        return <h2>{something.text}</h2>
    }
    if (something.type === 'heading3') {
        return <h3>{something.text}</h3>
    }
    if (something.type === 'heading4') {
        return <h4>{something.text}</h4>
    }
    if (something.type === 'heading5') {
        return <h5>{something.text}</h5>
    }
    if (something.type === 'heading6') {
        return <h6>{something.text}</h6>
    }
    if (something.type === 'paragraph') {
        return <p>{something.text}</p>
    }
}

const RichTextRender = (something: PrismicRichText | PrismicRichTextRaw | string | null) => {
    if (typeof something == "undefined" || _.isNull(something)) {
        return ""
    }
    if (typeof something == "string") {
        return something;
    }
    if (typeof something == "object" && something.props) {
        // This is a react element already 🤨
        return something;
    }


    try {
        if (something.hasOwnProperty('raw') || (Array.isArray(something) && something.length > 0 && something[0].spans)) {
            return RichText.render(something.raw, linkResolver);
        } else {
            throw new Error("Got something weird");
        }
    } catch (e) {
        try {
            return something.map(RichTextV2Render);
        } catch (e) {
            console.error({ something }, typeof something);
            throw e;
        }
    }
}

export default RichTextRender;
