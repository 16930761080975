import './hamburger.scss';
import classNames from "classnames";
import React from "react";

export const NavHamburger = (props: any) => {
    const classes = classNames('nav-hamburger', {
        'open': props.isOpen,
    });

    return (
        <div className={classes} onClick={props.clickHandler}>
            <span/>
            <span/>
            <span/>
            <span/>
        </div>
    );
};