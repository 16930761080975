import React from "react";
import { Helmet } from "react-helmet";

export const PageTitle = ({
  title,
  noAmazee = false,
  amazeeOnly = false,
}: {
  title?: string;
  noAmazee?: boolean;
  amazeeOnly?: boolean;
}) => {
  let text = "amazee.io";
  if (title) {
    text =  `${title}`;
  }

  return (
    <Helmet>
      <title>{text}</title>
    </Helmet>
  );
};
