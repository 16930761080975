import { getUrlVars } from './url_params';

export const getScrollToElement = (path = window.location.href) => {
  const urlVars = getUrlVars(path) as any;

  if (document && urlVars && urlVars.hasOwnProperty('scrollTo')) {
    const scrollToId = urlVars['scrollTo'] as string;
    return document.getElementById(`${scrollToId}`);
  }
};
