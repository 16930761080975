import React from "react";
import { PrismicPageDefinition } from "./queries/types";
import { Link } from 'gatsby';
import { getScrollToElement } from "./getScrollToElement";
import animateScrollTo from 'animated-scroll-to';

type LinkTarget = '_self' | '_blank';

const Linked: React.FC<{
    children: any,
    className?: string,
    link_to: PrismicPageDefinition,
    onClick?: () => void,
    target?: LinkTarget
}> = ({
    link_to,
    children,
    className,
    onClick = () => { },
    target = '_self'
}) => {
        let onlyScrollToChanging = null;

        if (!link_to || !link_to.document) {
            return null;
        }

        try {
            if (!link_to.document.data.is_external && typeof window !== 'undefined') {
                const t = `${window.location.pathname.split('?')[0]}.*[?&]scrollTo=`

                if ((new RegExp(t)).test(link_to.document.data.url)) {
                    onlyScrollToChanging = () => {
                        let el = getScrollToElement(link_to.document.data.url);
                        if (el) {
                            animateScrollTo(el);
                        }
                    }
                }
            }
        } catch (e) {
            console.error(e, { link_to });
        }

        if (link_to.document.data.is_external || target != '_self') {
            return (
                <Link target={target} href={link_to.document.data.url} className={className} onClick={onClick}>
                    {children}
                </Link>
            );
        } else {
            const params = {
                to: link_to.document.data.url,
                className: className,
            } as any;

            if (onlyScrollToChanging) {
                params.onClick = onlyScrollToChanging
            };

            return (
                <Link {...params} onClick={onClick}>
                    {children}
                </Link>
            );
        }
    };

export default Linked;
