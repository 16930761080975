import classNames from 'classnames';
import { graphql, Link, StaticQuery } from 'gatsby';
import * as React from 'react';
import { getNavigation, TypeNavigationQueryResult } from '../../utils/queries/navigation';

const ChildWrapper = ({ children, data }: any) => {
    const hasBanner = data.banner_enabled;
    const klasses = classNames('page-padded', {
        'has-banner': hasBanner,
    });

    return (
        <div className={klasses}>
            {children}
        </div>
    );
}

const StaticChildrenWrapper: React.FC<{ location: any, children: any[] }> = ({ location, children }) => {
    const navQuery = graphql`
    {
        allPrismicNavigation(limit: 1) {
          edges {
            node {
              data {
                  banner_enabled
              }
            }
          }
        }
      }`;

    return (
        <StaticQuery query={`${navQuery}`} render={(data: TypeNavigationQueryResult) => <ChildWrapper data={getNavigation(data)} children={children} />} />
    );
};


export default StaticChildrenWrapper;
