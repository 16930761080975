import "./button.scss";
import React from "react";
import classNames from "classnames";

export const AmazeeIOButton: React.FC<{
  skipPadding?: boolean;
  classes?: Array<string> | string;
  onClick?: () => void;
}> = ({ children, classes = "", onClick = () => {}, skipPadding = false }) => {
  const cls = classNames(
    "btn-boldish amazeeio-button rounded-full",
    {
      "px-4 py-2": !skipPadding,
    },
    classes
  );

  return (
    <div className={cls} onClick={onClick}>
      {children}
    </div>
  );
};

export const AmazeeIOButton2: React.FC<{
  skipPadding?: boolean;
  classes?: Array<string> | string;
  onClick?: () => void;
}> = ({ children, classes = "", onClick = () => {},  }) => {
  const cls = classNames(
    classes
  );

  return (
    <div className="amazeeio-button-2" onClick={onClick}>
      {children}
    </div>
  );
};

export const AmazeeIOButton3: React.FC<{
  skipPadding?: boolean;
  classes?: Array<string> | string;
  onClick?: () => void;
}> = ({ children, classes = "", onClick = () => {},  }) => {
  const cls = classNames(
    classes
  );

  return (
    <div className="mob-button w-full" onClick={onClick}>
      {children}
    </div>
  );
};
