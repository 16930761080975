const linkResolver = (doc: {
  uid: string;
  slug: string;
  type: string;
  data: { slug?: string; urlslug?: string; url?: string };
}) => {
  if (doc.node) {
    doc = doc.node;
  }
  if (doc.type === "leads_landing_page") {
    return `/why-companies-choose-amazee-io`;
  }
  if (doc.type === "pricing") {
    return `/pricing`;
  }
  if (doc.type === "get_started") {
    return `/get-started`;
  }
  if (doc.type === "pricing") {
    return `/pricing`;
  }
  if (doc.type === "blog_post") {
    return `/blog/post/${doc.uid}`;
  }
  if (doc.type === "about_us_page") {
    return `/about-us`;
  }
  if (doc.type === "aboutuspersonpage") {
    return `/about-us/people/${doc.uid}`;
  }
  if (doc.type === "careers_page") {
    return `/careers`;
  }
  if (doc.type === "cloud_h") {
    return `/product/cloud-hosting`;
  }
  if (doc.type === "customers_page_v2") {
    return `/about-us/customer-stories`;
  }
  if (doc.type === "dedicated_hosting") {
    return `/product/dedicated-hosting`;
  }
  if (doc.type === "landing_page") {
    return `/${doc.uid}`;
  }
  if (doc.type === "technology_landing_page") {
    return `/${doc.uid}`;
  }
  if (doc.type === "lagoon_page") {
    return `/lagoon`;
  }
  if (doc.type === "lagoon_roadmap") {
    return `/lagoon/roadmap`;
  }
  if (doc.type === "see_lagoon_in_action") {
    return `/lagoon/see-lagoon-in-action`;
  }
  if (doc.type === "support_services") {
    return `/services`;
  }
  if (doc.type === "apps_frameworks") {
    return `/apps-frameworks`;
  }
  if (doc.type === "page_definitions") {
    if (doc.slug === "homepage") {
      return `/`;
    }
    if (doc.slug === "blog") {
      return `/blog/page/1`;
    }
    if (doc.slug === "news") {
      return `/news/page/1`;
    }
    if (doc.slug === "the-people") {
      return `/about-us/people`;
    }
    if (doc.slug === "the-customers") {
      return `/about-us/customer-stories`;
    }
    if (doc.slug === "success-stories") {
      return `/blog/categories/success-stories/page/1`;
    }
    if (doc.slug === "dedicated-hosting") {
      return `/product/dedicated-hosting`;
    }
    if (doc.slug === "cloud-hosting") {
      return `/product/cloud-hosting`;
    }
    if (doc.slug === "product") {
      return `/product/`;
    }
    if (doc.slug === "services") {
      return `/services/`;
    }
    if (doc.slug === "support-services") {
      return `/services/`;
    }
    if (doc.slug === "managed-hosting-services") {
      return `/product/`;
    }
    if (doc.slug === "see-it-in-action") {
      return `/lagoon/see-it-in-action`;
    }
    if (doc.slug === "roadmap") {
      return `/lagoon/roadmap`;
    }
    if (doc.slug === "lagoon") {
      return `/lagoon`;
    }
    if (doc.slug === "get-in-touch") {
      return `/get-in-touch`;
    }
    if (doc.slug === "kubernetes-space-page") {
      return "/blast-off-with-lagoon-and-kubernetes";
    }
    if (doc.slug === "external-documentation") {
      return `https://lagoon.readthedocs.io/en/latest/`;
    }
    if (doc.slug === "external-twitter") {
      return `https://twitter.com/amazeeio`;
    }
    if (doc.slug === "external-status-page") {
      return `https://status.amazee.io/`;
    }
    if (doc.slug === "external-linkedin") {
      return `https://www.linkedin.com/company/amazee.io/`;
    }
    if (doc.slug === "external-instagram") {
      return `https://www.instagram.com/amazeeio/`;
    }
    if (doc.slug === "external-github") {
      return `https://github.com/amazeeio`;
    }
    if (doc.slug === "external-facebook") {
      return `https://www.facebook.com/amazee.io/`;
    }
    if (doc.slug === "external-amazee-group") {
      return `https://amazee.com/home`;
    }
    if (doc.slug === "cdn--waf") {
      return `/cdn-waf`;
    }
    console.error("Didnt find url for page_definition", doc);
  }
  if (
    doc.type === "footer" ||
    doc.type === "homepage" ||
    doc.type === "navigation"
  ) {
    return `/`;
  }
  return "";
};

export default linkResolver;
