import {
  PrismicExternalLink,
  PrismicImage,
  PrismicPageDefinition,
} from "./types";
export type TypeFooterV2LinkWithOverwritableText = {
  text: string;
  link: PrismicPageDefinition;
  link_rel: string;
};
export type TypeFooterV2LinkWithLogo = {
  logo: PrismicImage;
  link: PrismicPageDefinition;
  label: string;
};

export type TypeFooterV2LinkColumn = {
  slice_type: "link_column";
  primary: {
    title: string;
    title_link: PrismicPageDefinition;
  };
  items: TypeFooterV2LinkWithOverwritableText;
};

export type TypeFooterV2Data = {
  body: TypeFooterV2LinkColumn[];
  legal_links: TypeFooterV2LinkWithOverwritableText[];
  social_links: TypeFooterV2LinkWithLogo[];
  copyright: string;
  newsletter_cta_text: string;
  desktop_logo: PrismicImage;
  mobile_logo: PrismicImage;
  small_logo: PrismicImage;
  g2_logo: PrismicImage;
  g2_logo_link: PrismicExternalLink;

  g2_logo2: PrismicImage;
  g2_logo_link2: PrismicExternalLink;

  g2_logo3: PrismicImage;
  g2_logo_link3: PrismicExternalLink;
};

export type TypeFooterV2QueryResult = {
  allPrismicFooterV2: {
    nodes: Array<{ data: TypeFooterV2Data }>;
  };
};

export const getFooterV2 = (
  data: TypeFooterV2QueryResult
): TypeFooterV2Data => {
  return data.allPrismicFooterV2.nodes[0].data;
};
