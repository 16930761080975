import React from "react";

const UpCaret = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.727" height="6.942" viewBox="0 0 11.727 6.942">
      <path id="Path_31489" data-name="Path 31489" d="M11.044.73,5.864,5.573.683.73" transform="translate(11.727 6.942) rotate(180)" fill="none" stroke="#2bc0e4" stroke-miterlimit="10" stroke-width="2"/>
    </svg>
  )
}

export default UpCaret;
